<template>
  <div
    class="loading-part"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="isPlay" :id="sysUqNo" style="width: 100%; height: 100%" />
    <img style="width: 100%; pointer-events: none" v-else src="@/assets/images/previewSceen/defaultImg.png" alt />
  </div>
</template>
<script>
// const IS_MOVE_DEVICE = document.body.clientWidth < 992; // 是否移动设备
const MSE_IS_SUPPORT = !!window.MediaSource; // 是否支持mse
// import { getPreviewURLs } from '@/api/user/screenPreview/perviewApi.js';
import { getPreviewURLs } from '@/api/general-user/video-monitor.js';

export default {
  name: 'HikVideo',
  props: {
    sysUqNo: {
      type: String,
      default: () => {
        return '';
      }
    },
    // 视频监控分屏数
    splitNum: {
      type: Number,
      default: () => {
        return 1;
      }
    }
  },
  data() {
    return {
      loading: false,
      isPlay: true,
      // 海康视频参数
      player: null,
      // splitNum: 1,
      mseSupport: MSE_IS_SUPPORT,
      tabActive: 'decoder',
      playback: {
        // startTime: '2022-11-16T00:00:00',
        // endTime: '2022-11-16T23:59:59',
        valueFormat: '',
        // seekStart: '2022-11-16T12:00:00',
        rate: ''
      },
      muted: true,
      volume: 50,
      volumeOnSvg: {
        template:
          '<svg t="1624453273744" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1421" width="200" height="200"><path d="M597.994667 138.005333q130.005333 28.010667 213.994667 132.992t84.010667 241.002667-84.010667 241.002667-213.994667 132.992l0-88q93.994667-28.010667 153.002667-106.005333t59.008-180.010667-59.008-180.010667-153.002667-106.005333l0-88zM704 512q0 120-106.005333 172.010667l0-344q106.005333 52.010667 106.005333 172.010667zM128 384l170.005333 0 213.994667-213.994667 0 684.010667-213.994667-213.994667-170.005333 0 0-256z" p-id="1422"></path></svg>'
      },
      volumeOffSvg: {
        template:
          '<svg t="1624453193279" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9147" width="200" height="200"><path d="M512 170.005333l0 180.010667-90.005333-90.005333zM181.994667 128l714.005333 714.005333-53.994667 53.994667-88-88q-74.005333 58.005333-156.010667 77.994667l0-88q50.005333-13.994667 96-50.005333l-181.994667-181.994667 0 288-213.994667-213.994667-170.005333 0 0-256 202.005333 0-202.005333-202.005333zM810.005333 512q0-101.994667-59.008-180.010667t-153.002667-106.005333l0-88q130.005333 28.010667 213.994667 132.992t84.010667 241.002667q0 96-44.010667 178.005333l-64-66.005333q21.994667-53.994667 21.994667-112zM704 512q0 18.005333-2.005333 26.005333l-104-104 0-93.994667q106.005333 52.010667 106.005333 172.010667z" p-id="9148"></path></svg>'
      }
    };
  },
  created() {
    // 海康h5插件初始化
    setTimeout(() => {
      this.createPlayer();
      this.arrangeWindow();
      this.init();
    }, 100);
    setTimeout(() => {
      this.getPreviewUrl();
    }, 500);
  },
  destroyed() {
    this.stopAllPlay();
    this.destroyAll();
  },
  methods: {
    // 海康视频初始化加载
    init() {
      // 设置播放容器的宽高并监听窗口大小变化
      window.addEventListener('resize', () => {
        this.player.JS_Resize();
      });
    },
    createPlayer() {
      this.loading = true;
      this.player = new window.JSPlugin({
        szId: this.sysUqNo,
        szBasePath: '/js/', // 引入静态资源地址，我这里静态资源在public/js文件存放，所以设置为js
        iMaxSplit: 4, //默认最大分屏4*4
        iCurrentSplit: 1,
        openDebug: true,
        oStyle: {
          borderSelect: '#FFCC00'
        }
      });
      // 事件回调绑定
      // this.player.JS_SetWindowControlCallback({
      //   windowEventSelect: function (iWndIndex) {
      //     // 插件选中窗口回调
      //     // console.log('windowSelect callback: ', iWndIndex);
      //   },
      //   pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {
      //     // 插件错误回调
      //     // console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
      //   },
      //   windowEventOver: function (iWndIndex) {
      //     // 鼠标移过回调
      //     // console.log(iWndIndex);
      //   },
      //   windowEventOut: function (iWndIndex) {
      //     // 鼠标移出回调
      //     // console.log(iWndIndex);
      //   },
      //   windowEventUp: function (iWndIndex) {
      //     // 鼠标mouseup事件回调
      //     // console.log(iWndIndex);
      //   },
      //   windowFullCcreenChange: function (bFull) {
      //     // 全屏切换回调
      //     // console.log('fullScreen callback: ', bFull);
      //   },
      //   firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {
      //     // 首帧显示回调
      //     // console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
      //   },
      //   performanceLack: function () {
      //     // 性能不足回调
      //     // console.log('performanceLack callback: ');
      //   }
      // });
      return this.player;
    },
    // 分屏
    arrangeWindow() {
      this.player.JS_ArrangeWindow(this.splitNum).then(
        () => {
          // console.log(`arrangeWindow to ${this.splitNum}x${this.splitNum} success`);
        },
        e => {
          console.error(e);
        }
      );
    },

    // 获取取流连接
    getPreviewUrl() {
      const param = {
        deviceId: this.sysUqNo,
        streamType: 0,
        // netZoneCode: 1,
        protocol: 'ws',
        transmode: 0
      };
      getPreviewURLs(param).then(res => {
        this.realplay(res.data.url, 0);
      });
    },
    // 视频预览
    realplay(playURL, index1) {
      this.mode = 1; // 解码方式：0普通模式 1高级模式
      const { player, mode } = this;
      player.JS_Play(playURL, { playURL, mode: this.mode }, index1).then(
        () => {
          // console.log('realplay success');
          // 设置取流连接超时时间
          let nTime = 10000;
          player.JS_SetConnectTimeOut(index1, nTime).then(
            () => {
              console.info('JS_SetConnectTimeOut success');
            },
            err => {
              console.info('JS_SetConnectTimeOut failed', err);
            }
          );
          this.loading = false;
        },
        e => {
          console.error(e);
          this.isPlay = false;
          this.loading = false;
        }
      );
    },
    // 关闭所有视频
    stopAllPlay() {
      // const player = this.createPlayer();
      this.player.JS_StopRealPlayAll().then(
        () => {
          this.playback.rate = 0;
        },
        e => {
          console.error(e);
        }
      );
    },
    // 关闭单个视频
    stopPlay() {
      this.player.JS_Stop().then(
        () => {
          this.playback.rate = 0;
          const index = this.player.currentWindowIndex;
          this.selectAisle(this.videoList[index], index);
        },
        e => {
          console.error(e);
        }
      );
    },
    destroyAll() {
      // const player = this.createPlayer();
      this.player.JS_Destroy().then(
        () => {
          console.log('destroyAlldestroyAlldestroyAlldestroyAll');
        },
        e => {
          console.error(e);
        }
      );
    }
  }
};
</script>
<style scoped lang="less">
.loading-part {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}
</style>
